<template>
  <div
    class="content"
    style="
      display: block;
      width: 100%;
      max-width: 1200px;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
    "
  >
    <h2>隐私政策说明</h2>
    <br />
    <b>生效日期：2023-06-28 更新日期：2023-06-28</b>
    <br />
    铂诺客智能科技（北京）有限公司及其关联方，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定“隐私政策”并提示您在使用
    <b>【巡检GO】</b>及相关服务前仔细阅读并理解本隐私政策，以便做出适当选择。
    <br />
    感谢您的信任及使用本平台服务和产品。基于互联网信息及平台服务，用户账号关联到用户个人信息管理及隐私的保护尤为重要，在深知个人信息对用户的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息安全。同时，请您提前知晓并理解，在您使用平台服务时，平台希望通过隐私政策向您说明如何处理您的个人信息和使用您相关个人信息时对应的处理规则等相关事宜，以及平台为您提供的访问、修正、删除与保护相关信息的方式，以便更好的为您提供服务及保障您的权益。
    <br />
    特别提示
    <br />
    您在使用我们提供的功能服务和产品时，请您仔细阅读并充分理解《隐私政策》并作出相应选择，在当您主动勾选用户协议及隐私政策，即表示您同意隐私政策说明，在您同意隐私政策，并在登录使用或享受的本平台服务/产品，意味着您同意本平台按照本隐私政获取您的相关信息。其中本平台服务/产品是指本平台网络及技术服务提供者通过下述途径对您提供的服务或产品：包括本平台、小程序、客户端服务。
    <br />
    除上述平台服务/产品外，针对一些特定的服务/产品，将会根据政策规则制定特定的隐私政策内容说明。如特定的隐私政策与本隐私政策有不一致地方，适用该特定隐私政策说明。同时，依据法律规定，平添将会遵循正当、合法、必要的原则，出于本政策所述的以下目的，获取、保存、适用、分享您在使用服务过程中主动提供或产生的个人信息，帮助您成为平台用户，为您提供服务/产品，不断优化用户体验并提供安全保障。
    <br />
    本平台致力于打造多样产品和服务以满足您的要求，为实现向您提供本平台的产品及服务的基本功能，在您阅读本政策内容后，您须授权我们收集、使用相关必要信息，以保证您正常使用本平台功能及产品服务。因为是打造多样的产品及服务，所以向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体产品/服务功能为准。同时，为了不断提升用户体验，产品与系统会不断优化，涉及功能、系统、版本的持续迭代升级，需要收集、使用新的个人动态信息或变更个人信息使用目的。对此，将会通过更新本隐私政策、页面提示、弹窗说明等方式向您说明，并提供自主选择供您选择是否同意。针对上述内容涉及的信息数据均会进行安全保障，关于是否使用本平台相关功能及服务，以及包含获取收集使用相关信息的说明，请认真阅读并理解本政策，一切均由用户（您）自主选择。
    <br />
    <br />
    本隐私政策相关部分说明将帮助您了解一下内容：
    <b> </b>
    <br />
    <b>1、如何收集及使用您的个人信息；</b>
    <br />
    <b>2、如何使用cookie和同类技术的； </b>
    <br />
    <b>3、如何分享、转让或披露您的信息；</b>
    <br />
    <b>4、如何保护您的个人信息； </b>
    <br />
    <b>5、如何管理您的个人信息； </b>
    <br />
    <b>6、第三方服务的说明；</b>
    <br />
    <b>7、如何处理未成年信息保护说明； </b>
    <br />
    <b>8、政策内容的通知和修订；</b>
    <br />
    <b>9、如何联系我们及意见反馈； </b>
    <h3>一、如何收集及使用您的信息</h3>
    <br />
    在您使用本平台相关服务和产品，出于本政策所述的以下目的，需要收集和使用您的信息。
    <br />
    （一）基于为您的提供相关服务，为实现向您提供本平台的产品及服务的基本功能，您须授权我们收集、使用相关必要信息。将通过以下途径收集和获得您的个人信息；如您拒绝提供将无法正常使用本平台产品及服务。
    <br />
    （1）在您注册为本平台用户，注册账号或使用相关服务时，向平台提供的信息；（涉及用户的姓名、联系电话、住址、身份证号、微信头像及关联信息、定位信息、存储卡相关信息授权信息等）
    <br />
    （2）您通过使用平台服务向其他方提供的共享信息，以及您使用平台时所储存的信息。
    <br />
    （3）在您注册登录后，并操作使用服务时，将会获取你提供的相关信息，用于服务操作。
    <br />
    注意说明：如您在平台服务中其他用户可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的信息，该等信息可能会被他人收集或加以使用。对此，当您发现他人不正当收集或使用您的信息时，请注意做好您个人信息的保护及其他损耗个人财产行为信息的甄别。也可通过本政策中的联系方式联系我们，与您共同去解决。
    <br />
    （二）基于为您提供服务，出于以下情况提供您的以下个人信息：
    <br />
    （1）注册为用户、登录及成为会员
    <br />
    在您登录注册操作时，界面同步展示《用户协议》及《隐私政策说明》，供您阅读选择操作，当您主动勾选即默认为您同意相关协议及隐私内容说明。在您同意隐私政策后，我们将开始获取您的相关信息，以方便操作平台功能及相关服务。
    <br />
    为方便您使用平台服务，您需要注册或登录（如登录账号、手机号等），创建您的个人账号、用户名和登录密码、以及使用基础功能，如巡检、招商。同时，在您注册个人账号、登录及后续使用过程中，需填写或补充其他个人信息（如您的昵称、头像等，前述信息并不是全部为必填项信息，根据个人意愿）。
    <br />
    （2）用户信息整理
    <br />
    在您创建账户时需您填写信息（电话、姓名等），在您同意本隐私政策后将收集相关信息用于识别核准用户信息、回应查询等方式为您提供服务。
    <br />
    （3）信息收集：平台服务为目的
    <br />
    若您想直接使用平台的功能，则您必须完成注册。同时，您承诺并保证：为实现本平台服务之目的，平台通过技术手段收集您的个人信息，如您的电话号码、头像、昵称及登录密码等使用平台所必需的信息。
    <br />
    同时，本平台支持多类操作系统使用：您通过相关手机操作系统使用本平台服务时，平台所收集的信息仅限于您同意使用本平台基本服务所必需的信息。
    <br />
    基于平台相关服务及功能操作，本平台需要获取应用权限如下：
    <br />
    1.存储，允许读取存储卡上的照片，文件及媒体内容，允许修改和删除存储卡内的内容，修改或删除存储卡上的照片、文件及媒体内容。方便您进行拍照、图片上传、删除等操作。
    <br />
    2.相机，访问相机，拍摄照片及视频，用于业主进行问题反馈，及后期问题解决凭证。
    <br />
    3.其他信息，与蓝夜设备配对、查看Wi-Fi连接或断开、安装快捷方式、修改系统设置等。
    <br />
    （4）用户提供
    <br />
    若您想使用更多服务功能，如分享-微信第三方平台，因此需您同意并授权相关功能服务，以便您分享操作。同时，为了方便向您交付商品或服务，需您提供收货人姓名、收货人地址、联系电话。若平台委托第三方向您交付时，平台会征得您同意后将上述信息共享给第三方。若您拒绝提供此类信息，后续将无法提供相关交付服务，请您仔细阅读。
    <br />
    经过您的同意及认可后，您注册用户并同意隐私政策说明开始，您填写的信息，平台将最大限度的合理于商业义务进行保密。平台向您提供工作进度等资讯公告、版本升级等服务。当您修改相关信息可先行自行修改操作，若需要的协助修改相关信息（如住户绑定认证、手机号等），可能需要提供上述信息之外的其他信息予以说明以便协助完成您的修改需求。
    <br />
    （5）基础服务、网络信息
    <br />
    在您使用平台相关功能服务时，为了保障账号稳定，识别异常状态，以及产品的适配性，在您操作相关功能、浏览、查看、编辑上传等基本服务，在您授权同意前提下，可能会收集获取、储存关于您使用的服务及使用方式的信息并将相关信息进行关联；
    <br />
    ①　日志信息：当你使用平台相关服务时，可自动收集您使用服务的详细内容，作为有关网络日志保存。包括您的登录账号、搜索查询内容、IP地址、网络环境、访问日期。
    <br />
    地址、网络环境、访问日期。
    <br />
    ②　设备信息：在您同意隐私政策说明后，根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、设备ID、互联网协议地址、您访问的时间和地点、操作系统及版本、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）、电信运营商等软硬件信息，以及其他类似特征记录。通过获取设备信息，用于移动端软件下载及软件正常使用，使用状态，以及不同设备后续软件系统操作bug的修复及检测使用。
    <br />
    ④　特别说明：为了收集上述基本个人设备信息，平台将会申请访问您的设备信息的权限，在您授权同意后才会获取相关信息。让您授权是为了向您提供功能服务操作。
    <br />
    ⑤　特别注意：若涉及单独的设备信息，日志信息时无法识别特定自然人身份的信息。如果平台将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合试用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另外规定外，我们会将该类个人信息做匿名化、去标识化处理。该类信息在结合使用期间，将会作为您的个人信息按照本隐私政策处理与保护。
    <br />
    信息使用说明
    <br />
    1）除本隐私政策未载明或本隐私政策的更新未能提前通知您的情况下，您的个人信息将不会用于其他目的。
    <br />
    2）匿名汇总统计数据不是本平台所定义的个人信息，我们将为多种目的，包括但不限于分析和使用说明的汇报报告，来展示并使用。平台将保留以任何目的或单方面许可第三方使用和披露匿名汇总统计数据的权利，对此数据信息的整理的也将会做到安全处理及安全保障。
    <br />
    3）当您在使用本平台服务及产品时所提供的所有个人信息，除您自行删除或通过系统设置拒绝访问及收集，否则将在您使用平台期间授权我们使用。在您注销账号时，平台将根据相关规定停止使用并删除您的个人信息内容。
    <br />
    （6）推送通知
    <br />
    ①　平台可能在必要时，会以信息推送方式或其他提示方式，向您发送相关服务的通知内容。（如由于系统维护、迭代升级、功能错误、软件损坏、系统被攻击等，而不得暂停某一单项服务、变更、终止提供某一单项服务时）
    <br />
    对于上述通知内容，如您不需要或拒绝接受，可在移动设备上自行设置，不再接受即可。但是，相关依法律规定或单项服务的协议内容等必须发送内容，还请您仔细阅读并理解。
    <br />
    （7）关于信息使用，若本政策内未载明其他用途，或基于服务获取信息用于其他目的，会提前和您沟通联系，在征得您的同意和授权后方可使用。
    <br />
    （8）安全保障
    <br />
    为提高您使用平台及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、网络病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或平台相关协议规则的情况，我们可能会获取、使用或整合您账号信息、交易信息、设备信息、日志信息以及关联公司、伙伴取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要后续处理措施。
    <br />
    （9）数据统计分析
    <br />
    根据服务产品，基于业务统计分析，可能会定期对平台提供的产品或服务的使用情况进行统计分析，对于该统计数据可能会与公众或第三方共享该统计信息，以此展示。
    <br />
    （10）无需征得授权同意情况说明
    <br />
    根据法律法规规定，以下情形中收集使用您的信息无需征得您的授权同意，请您知悉；
    <br />
    1）与国家安全、国防安全有关的；
    <br />
    2）与公共安全、公共卫生、重大公共利益有关的；
    <br />
    3）与犯罪侦查、起诉、审判和判决执行等有关的；
    <br />
    4）
    出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    <br />
    5）所收集的信息是您自行向社会公众公开的；
    <br />
    6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
    <br />
    7）根据您的要求签订合同所必需的；
    <br />
    8）用于维护服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
    <br />
    9）为合法的新闻报道所必需的；
    <br />
    10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
    <br />
    11）法律法规规定的其他情形；
    <br />
    请知悉，无法与任何特定个人直接建立联系的数据，不属于个人数据。若将无法与任何特定个人建立联系的数据与其他信息结合用于识别自然人个人身份，或将其与个人信息结合使用，则在结合使用期间，此类信息将被视为非个人信息。
    <br />
    （11）有关个人敏感信息的提示
    <br />
    在您同意本隐私政策和相关授权后，以上由您提供或获得的信息中，可能包含您的个人敏感信息，如个人手机号、证件号码、交易信息。请您谨慎并留意个人信息，您同意您的个人信息因平台可以按照本政策内容所述的目的和方式处理。
    <br />
    <h3>二、如何使用cookie和同类技术</h3>
    <br />
    1、为了确保正常操作及运转，让你拥有便捷轻松的访问体验。我们可能通过cookies或同类技术获取和使用您的信息，并将该信息储存在您的日志信息。
    <br />
    2、使用cookies让您的操作登录平台过程上方便快捷，花费少的时间即可获得相关服务。我们向您发送的cookie是为了简化您重复登录的步骤、存储您的设置、商品、服务等，帮助您优化软件互动，帮助判断您的登录状态以及账户数据安全。
    <br />
    使用cookie或同类技术，可能有以下用途：
    <br />
    1）记住您的身份，通过cookies同类技术有助于辨别您的注册用户身份，或保存你提供的有关您的喜好或其他信息。
    <br />
    2）分析使用平台服务的情况，通过cookie或同类技术可了解使用哪些活动，哪些服务使用频次最高。
    <br />
    <h3>三、如何分享、转让或披露您的信息</h3>

    <br />
    （一）分享说明
    <br />
    除以下情况外，未经过您的同意，我们不会与本平台之外的任何第三方分享您的个人信息，请您仔细阅读并理解；
    <br />
    1）在您注册登录时请详细阅读协议及隐私政策说明，当您注册或登录后即可默认您已同意相关信息的获取及信息服务共享（以便为您正常提供功能及产品服务）。相关信息是在获得您授权及同意后，会与其他方服务共享您的个人信息。
    <br />
    2）对于您的信息的共享是出于提供更好更便捷的服务为主，同时也是在法定情形下共享，会根据法律法规规定、诉讼争议解决需求，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    <br />
    3）为了给您提供及时和好的服务体验，我们可能会向本平台其他用户、合作伙伴、及其他第三方分享您的信息，包括不限于您分享的图片、视频、资料、数据等，以实现平台服务的功能，方便您正常使用您需要的服务。
    <br />
    4）与关联方的必要分享。为了便于基于平台服务向您提供产品和服务，识别账号异常，保护用户及关联单位或其他用户的人身财产安全免遭侵害。若涉及到分享您的个人敏感信息或关联单位改变个人信息的使用及处理目的，将再次征得您的授权同意后方可进行信息的分享。
    <br />
    5）基于学术研究，如科研机构开展统计或学术研究；基于符合法律法规的社会公共利益等。
    <br />
    （二）转让说明
    <br />
    我们不会将您的个人消息转让给任何公司、组织和个人，但以下情况除外：
    <br />
    1）事先在获取您的明确同意或授权情况；
    <br />
    2）根据适用法律法规、法律程序的要求、强制性行政或司法要求所必须的情况进行提供；
    <br />
    3）符合与您签署的相关协议（包括在线签署的电子协议以及相应平台规则）或其他法律文件约定所提供；
    <br />
    4）在涉及合并、收购、资产转让或类似交易时，如涉及个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
    <br />
    （三）委托处理
    <br />
    为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托关联公司或其他专业机构代表我方来处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。
    <br />
    （三）公开披露
    <br />
    我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才可能公开披露您的个人信息：
    <br />
    1）根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
    <br />
    2）依据法律法规的要求，强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调差函等。
    <br />
    （四）共享、转让、委托处理、公开披露个人信息时事先征得授权同意的例外。以下情况下，共享、转让、委托处理、公开披露您的个人信息无需事先征得您的授权同意：
    <br />
    1）与国家安全、国防安全有关的；
    <br />
    2）与公共安全、公共卫生、重大公共利益有关的；
    <br />
    3）与犯罪侦查、起诉、审批和判决执行等有关的；
    <br />
    4）出于维护您或其他个人生命、财产等重大合法权益但又很难得到本人同意的；
    <br />
    5）其他维护公共利益的情形，例如您的信用评价信息需要被公开;
    <br />
    6）您自行向社会公众公开的个人信息；
    <br />
    7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    <br />
    8）根据法律法规、共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方式无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。
    <br />
    请注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请你在使用我们的服务时更加谨慎的考虑，是否发布甚至公开分享相关信息。
    <br />

    <h3>四、如何保护您的个人信息</h3>
    <br />
    （一）个人信息的保存
    <br />
    1）除非依据法律法规或双方约定，我们仅会在实现目的所必需的最短时间内留存您的相关个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。如若您的个人信息超出保留期间或主动注销账号时，我们将依据法律法规的要求尽快删除您的个人信息或作匿名化处理。若平台终止服务或运营，我们将根据规定及时停止续集收集您的信息的活动，同时遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
    <br />
    2）原则上我们在中华人名共和国境内运营中收集和产生的个人信息，储存在中国境内，以下情形除外：A、法律法规有明确规；B、获得您的授权及同意；在上述情况中，我们会要求数据接收方按照本隐私政策以及其他相关的安全保密措施来处理个人信息。
    终止运营：若发生终止运营情形，我们将会及时通知您，并在终止运营后对您的个人信息进行删除或匿名化处理。
    <br />
    终止运营：若发生终止运营情形，我们将会及时通知您，并在终止运营后对您的个人信息进行删除或匿名化处理。
    <br />
    3）互联网并非绝对安全的环境，在您使用平台时，强烈建议您不要使用非本平台推荐的通信方式发送个人信息。如果您发现您自己的个人信息尤其您的账户或密码发生泄漏，请您立即联系平台服务，以便通过的您的申请采取相应保护措施。
    <br />
    （二）个人信息的保护措施
    <br />
    1）数据安全保护措施，为保障您的信息安全，我们会努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
    <br />
    2）安全认证，我们将努力提高信息安全保护能力，不断提高应对各种风险的能力，并与监管机构、第三方测评机构建立良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。
    <br />
    3）请您知悉并理解，互联网并非绝对的安全环境，我们强烈建议您通过安全方式，使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄露，尤其是您的账户和密码发生泄露，请您立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。
    <br />
    4）安全事件，在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求向你告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件有关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们将尽合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。对于平台来说，个人用户的信息非常重要，我们将尽合理的商业努力保障您的个人信息安全，并实施合理的商业程序限制非授权访问、使用或者披露您的个人信息。尽管采取上述措施，但是您应该知晓平台不能完全避免与个人信息相关的安全风险。
    <br />
    请您务必保管好自己的账户、密码及其他身份要素。您在使用平台服务时，我们会通过您的账号、密码及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并产生相关不利影响。如您发现账号、密码或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
    <br />
    <h3>五、如何管理您的个人信息 您对您的个人信息享有以下权利：</h3>
    <br />
    （一）您有权访问、修改、补充、删除您的个人信息、法律法规规定例外情况除外。您可以通过以下方式管理您的信息：1）账户信息，您可以访问、更正您账户中的个人资料及账号信息（身份证信息除外）、支付信息、账号绑定信息等，也可以更改您的密码，您可以通过访问本平台在设置中执行此类操作。2）地址信息，您可以通过访问本平台上的收货地址中随时添加、更改、删除您的地址信息（包括收货人姓名、地址、电话等信息）。
    <br />
    若您无法通过上述链接管理这些个人信息，以及除上述信息外，对于您在使用我们的产品/服务过程中产生的其他个人信息，若您需要访问、更正、删除或匿名化处理的，请随时联系我们，我们将及时回复您的访问请求。以下情况中，您可以向我们提出删除个人信息的请求：
    <br />

    1）若我们处理个人信息的行为违反法律法规；
    <br />
    2）若我们收集、使用您的个人信息，却未征得您的同意；
    <br />
    3）若我们处理个人信息的行为违反了与您的约定；
    <br />
    4）若我们终止运营及服务；
    <br />
    5）若您不再使用我们的产品和服务，或您主动注销了账号；
    <br />
    若我们确定响应您的删除要求，我们还将同时尽可能通知从我们获得您的个人信息实体，要求及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。若我们响应您的删除请求，我们可能无法立即从备份系统中删除相应的信息，但会在备份系统更新时进行清除或匿名。
    <br />
    （二）改变您授权同意的范围或撤回授权
    <br />
    您理解并同意，鉴于目前平台处于初期上线版本，在此期间您将暂时无法通过用户端更改或撤回您的授权。为最大程度保护您的合法权利，若您希望更改或撤回您的授权，请您立即停止使用本平台并向我们发出更改或撤回授权的通知（联系方式见服务协议）。我们在收到您的通知后将会在软件后台进行相应操作，对您的授权进行更改或撤回。我们将在后期软件更新后增加用户端的授权更改或撤回功能。请你理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权而开展的个人信息处理。
    <br />
    （三）注销账户
    <br />
    您可以通过移动端APP或平台访问“我的—个人信息—注销账号”点击注销即可完成账户的注销。您注销账户后，我们将停止为您提供产品/服务，并依据您的要求，除法律法规另有规定外，我们将删除或匿名化您的个人信息。
    <br />
    （四）响应您上述请求的说明
    <br />
    若您无法通过上述方式访问、更正、补充或删除您的个人信息以及注销账号，或若您认为本平台在违反法律法规的规定收集、使用个人信息的情形，您可以通过我们的客服或通过本隐私政策提供的其他方式与我们取得联系，我们将及时据您的请求沟通解决。同时，在以下情形中，按照法律法规要求，我们将无法响应你的请求：
    <br />
    （1）与国家安全、国防安全有关的；
    <br />
    （2）与公共安全、公共卫生、重大事件利益有关的；
    <br />
    （3）与犯罪侦查、起诉和审判等有关的；
    <br />
    （4）有充分证明表明您存在主观恶意或滥用权力的；
    <br />
    （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
    <br />
    （五）安全说明
    <br />
    对我们来说，个人信息的安全非常重要。我们将尽合理的商业努力保障您的个人信息安全，并实施合理的商业程序限制非授权访问、使用或者披露您的个人信息。尽管采取上述措施，但是您应当知晓本平台不能完全避免与个人信息相关安全危险。例如：1）若涉及评论时，用户自行将账号信息或相关个人信息展示对外，将极容易造成用户信息泄露及后续不良后果影响。2）对他人提供账户信息并让其登录操作使用等情况。对此，在拥有个人账号的同时，请一定保护好您的个人信息及账号密码，切勿随意提供给他人或在公开展示其账号信息。
    <br />
    <h3>六、第三方服务的说明</h3>
    <br />
    平台可能接入或链接至第三方提供的社交平台或其他服务，其中包括：
    <br />
    （1）您可以通过分享操作分享平台服务/产品到第三方服务。
    <br />
    （2）我们可能通过广告或服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；
    <br />
    （3）其他接入第三方服务（SDK）的情形。当您在使用平台时，在某些特定使用场景下，平台可能会使用具有相应业务资质及能力的第三方服务商提供的软件服务工具包（简称SDK）来为您服务，在您同意协议及隐私政策内容前提下，依照本政策，关联服务第三方将会获取相关信息为您提供服务。具体大致包括以下几种及相关服务说明（如下）：
    <br />
    1、数据分享操作SDK（依赖微信、腾讯分享或操作SDK）。为了方便您使用分享功能以及分析APP或平台在您设备上运行的情况，我们使用微信分享操作SDK服务，通过SDK以上相关信息同步用于实现APP或平台内信息分享功能。
    <br />
    收集个人信息类型：
    <br />
    设备参数及系统信息(设备类型、设备型号、系统版本、及相关硬件信息)：用于识别用户的设备类型、设备型号、系统版本等，确保消息准确下发；
    <br />
    设备标识符（IMEI、IDFA、AndroidID、GID、
    MAC、OAID、VAID、AAID、IMSI、MEID、UAID、SN、ICCID、SIM信息）：用于识别唯一用户，保证推送的精准送达及推送信息的准确统计
    <br />
    网络信息(IP 地址、WiFi
    信息、基站信息、DNS地址、DHCP地址、SSID、BSSID)与位置信息（经纬度）：用于优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性，同时实现区域推送功能；
    <br />
    应用列表信息(应用崩溃信息、通知开关状态、APP或平台应用列表及活跃状态、APP或平台应用页面信息、APP或平台功能事件相关信息)：当一个设备有多个APP或平台的推送链路同时活跃时，我们采用合并链路技术，随机合并成一条链路，以达到为用户节省电省流量的目的。
    <br />
    2、定位-位置SDK服务。用于获取用户所在的发货与收货位置，该SDK需获取用户位置信息，同步以上信息到第三方服务，用于APP或平台定位及关联功能使
    <br />
    <h3>七、如何处理未成年信息保护说明</h3>
    <br />
    1）为用户提供更好服务的同时，平台会收集相关信息为功能服务赋能。其中，涉及用户为未成年时，平台非常重视对未成年人个人信息收集和使用的安全性保护。我们建议，任何16周岁以下的未成年人参加网上活动应事先取得家长或其法定监护人的同意的前提下使用我们的服务或向我们提供相关信息。
    <br />
    2）本平台不会使用未成年人的个人信息，也不会向任何第三方透露可识别的信息。
    <br />
    3）对于经过父母或监护人同意使用我们产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母和监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    <br />
    4）若发现在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年的个人信息，则会设法尽快删除相关数据。
    <br />
    <h3>八、政策内容的通知和修订</h3>
    <br />
    为了给您提供更好的服务，随着功能服务和业务不断的更新变化及法律法规的相关要求，本隐私政策也将适时改变调整。在未经过您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会通过平台公告、移动端上发出更新版本或以其他方式提醒您相关内容的更新、也请您访问我们以便及时了解最新的隐私政策。如您不同意该等变更，可以选择停止使用本平台相关服务；如若继续使用平台服务，即表示您已充分阅读、理解并同意受修订后的政策的约束
    <br />
    2）对于重大变更，我们还会提供更为显著的通知，包括不限于通知公告、推送通知、发送邮件、短信或在本平台首页公示甚至向您提供弹窗提示等方式来通知您，说明个人信息保护政策的具体变更内容。
    <br />
    本政策内容所指的重大变更包括但不限于：
    <br />
    （1）我们的服务模式发生重大变化：如处理个人信息的目的、处理个人信息类型、个人信息的使用方式等；
    <br />
    （2）我们在所有权结构、组织架构等方面发生重大变化：如平台系统停止服务、业务调整、破产并购等引起情况下。
    <br />
    （3）个人信息获取、共享或公开披露的主要对象发生变化；
    <br />
    （4）您参与个人信息处理方面的权利及其行使方式发生重大变化；
    <br />
    （5）涉及平台处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    <br />
    （6）个人信息安全影响评估报告表明存在高风险时。
    <br />
    （7）由于网络故障，外部攻击，例如网络攻击，信息病毒等，导致用户无法使用相关服务功能时。
    <br />
    我们在发布的对隐私政策所做的任何变更，并做相应提示，若您继续使用则视为您同意更新后内容，并认可本隐私内容涉及的内容说明。
    <br />
    <h3>九、如何联系我们及意见反馈</h3>
    <br />
    如果您对于我们的相关服务或者其他方面有疑惑或建议投诉的内容，您可以联系我们的服务人员进行反馈说明，向微信公众号客服咨询：巡检GO。针对您反馈的问题，我们将在核验您的身份后及时回复您的诉求并尽力解决。同时，您也可以登录APP或平台，进行建议反馈，填写您的疑问、投诉及反馈意见，我们将积极及时进行查证、处理并尽快答复你。
    如您已注册登录使用平台，请随时关注动态内容说明，如有问题或疑问可咨询工作人员，我们将及时为您解惑答疑。同时，您注册登录使用平台，即已默认您认可并理解政策协议内容说明，请为个人信息安全及服务正常使用遵守相关协议说明及相关条款注意事项说明
  </div>
</template>
<style>
.content p {
  margin-top: 8px;
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 0;
  text-align: justify;
  text-justify: inter-ideograph;
  font-size: 16px;
  color: #000000;
}
</style>
<script>
console.log("获取标题", document.title);
// 修改标题
document.title = "巡检GO";
</script>
